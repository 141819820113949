import { gql } from '@apollo/client';

const getGuardian = gql`
  query GetGuardian {
    guardian {
      id
      fullName
      phoneNumber
      email
      canBookMarketplaceActivities
      marketingOptIn
      children {
        id
        fullName
        birthDate
        isAdult
      }
    }
  }
`;

export default getGuardian;
