import type { AppProps } from 'next/app';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/carousel/styles.css';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import { Auth, Amplify } from 'aws-amplify';
import { ApolloProvider } from '@apollo/client';
import { GraphQLClient, log } from '@pebble/common';
import { AppShell, MantineProvider, Center, CSSVariablesResolver } from '@mantine/core';
import { ReactElement, useEffect, useState } from 'react';
import * as FullStory from '@fullstory/browser';
import { trackPageView } from 'utils/amplitude';
import { IntercomProvider, setIntercomUser } from '@utils';
import PebbleTheme from 'theme';
import { PebbleNavbar } from 'components/ui';
import { usePageLoading } from 'hooks/usePageLoading';
import WithGuardian from 'components/WithGuardian';

import SEO from '../../next-seo.config';

import '../styles.css';
import { Guardian } from 'types';
import { EmbedContextProvider } from 'context/EmbedContext';
import { useMediaQuery, useSessionStorage } from '@mantine/hooks';
import getUTMParamsFromURL from 'utils/getUTMParamsFromURL';
import { BasketProvider } from 'context/BasketContext';

import { detectIncognito } from 'detectincognitojs';
import { SavedSearchFilterValuesProvider } from 'context/SavedSearchFilterValuesContext';
import { GoogleContextProvider } from 'context/GoogleContext';
import { Pebbles } from '@ui';
import { AmplitudeContextProvider } from 'context/AmplitudeContext';
import { ProfileContextProvider } from 'context/ProfileProvider.context';

const amplifyConfigObject = {
  region: 'eu-west-1',
  userPoolId: process.env.NEXT_PUBLIC_COGNITO_POOL_ID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
  ssr: true,
  cookieStorage: {
    domain: process.env.NEXT_PUBLIC_AMPLIFY_COOKIE_DOMAIN, //localhost or .<domain>.com
    path: '/',
    expires: 365,
    sameSite: 'strict',
    secure: process.env.NEXT_PUBLIC_AMPLIFY_COOKIE_DOMAIN !== 'localhost',
  },
};

Amplify.configure(amplifyConfigObject);
Auth.configure(amplifyConfigObject);

const SetUtmParams = () => {
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [utmParams, setUtmParams] = useSessionStorage<Record<string, string>>({
    key: 'pebble_utm_params',
    defaultValue: {},
  });

  useEffect(() => {
    const utmParamsFromURL = getUTMParamsFromURL();
    if (Object.keys(utmParamsFromURL).length === 0) return;

    setUtmParams((prevUtmParams) => ({ ...prevUtmParams, ...utmParamsFromURL }));
  }, [setUtmParams, router.pathname]);

  return <></>;
};

const MyApp = ({ Component, pageProps }: AppProps): ReactElement => {
  const router = useRouter();
  const isMobile = useMediaQuery('(max-width: 768px)', false);
  const navbarHeight = isMobile ? 64 : 84;
  const [isEmbed, setIsEmbed] = useState<boolean>(false);
  const [iframeTheme, setIframeTheme] = useState<string>('');
  const [iframeLocation, setIframeLocation] = useState<string | null>(null);
  const [isChromeIncognito, setIsChromeIncognito] = useState<boolean>(true);
  const { isPageLoading } = usePageLoading();

  const URLpathname = router.pathname.split('/')[1];

  const supplierActivitiesRoute =
    router.pathname.split('/').includes('supplier') &&
    router.pathname.split('/').includes('activities');

  useEffect(() => {
    const sendActivityAndSupplierData = ['iframe', 'activity', 'checkout', 'waitlist'].includes(
      URLpathname,
    );

    if (!sendActivityAndSupplierData && window) {
      // Set local storage properties to null if not on activity or checkout routes to prevent sending supplierId/activityId if the user redirects away from ADP
      window.sessionStorage.setItem('supplierId', JSON.stringify(null));
      window.sessionStorage.setItem('activityId', JSON.stringify(null));
    }
  }, [URLpathname]);

  useEffect(() => {
    const themeParam = router.query?.theme;
    const selectedIframeTheme = Array.isArray(themeParam) ? themeParam[0] : themeParam;
    if (selectedIframeTheme) {
      setIframeTheme(selectedIframeTheme);
    }
  }, [router.query?.theme, isEmbed]);

  const loadGuardianInIntercom = (guardianDetails: Guardian | null) => {
    if (!guardianDetails || guardianDetails === null) {
      setIntercomUser(null);
      return;
    }

    const { id, fullName /*, intercomHash */ } = guardianDetails as Guardian;

    setIntercomUser(id, fullName /*, intercomHash */);
  };

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG !== undefined) {
      FullStory.init({
        devMode: process.env.NEXT_PUBLIC_ENV !== 'production',
        orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG,
      });
    }

    detectIncognito().then(({ browserName, isPrivate }) => {
      setIsChromeIncognito(browserName === 'Chrome' && isPrivate);
    });
  }, []);

  useEffect(() => {
    const excludedRoutesFromTracking = [
      URLpathname === 'activity',
      URLpathname === 'checkout',
      URLpathname === 'iframe',
      URLpathname === 'franchise',
      URLpathname === 'supplier',
      supplierActivitiesRoute,
    ];

    const onExcludedRoute = excludedRoutesFromTracking.some((element) => element === true);

    if (onExcludedRoute) return;

    trackPageView(router.pathname);
  }, [URLpathname, supplierActivitiesRoute, router.pathname]);

  useEffect(() => {
    if (iframeLocation !== null) return;

    if (supplierActivitiesRoute) {
      return setIframeLocation('supplier_iframe');
    }

    if (URLpathname === 'activity') {
      if (
        router.query.iframe_location === 'supplier_iframe' ||
        router.query.iframe_location === 'activity_iframe' ||
        router.query.iframe_location === 'widget_iframe'
      ) {
        return setIframeLocation(router.query.iframe_location);
      } else if (isEmbed) {
        return setIframeLocation('activity_iframe');
      } else {
        return setIframeLocation(null);
      }
    }

    if (URLpathname === 'iframe') {
      return setIframeLocation('widget_iframe');
    }
  }, [iframeLocation, supplierActivitiesRoute, URLpathname, router.query, isEmbed]);

  useEffect(() => {
    setIsEmbed(window.location !== window.parent.location);
  }, [setIsEmbed]);

  useEffect(() => {
    log(
      'NEXT_PUBLIC_REACT_APP_GRAPHQL_ENDPOINT: ',
      process.env.NEXT_PUBLIC_REACT_APP_GRAPHQL_ENDPOINT,
    );
    log('NEXT_PUBLIC_VERCEL_ENV: ', process.env.NEXT_PUBLIC_VERCEL_ENV);
  }, []);

  const resolver: CSSVariablesResolver = (theme) => ({
    // To add css variable name to custom variables from the other theme object
    variables: {
      '--mantine-other-midnight': theme.other.midnight,
      '--mantine-other-pinkHover': theme.other.pinkHover,
      '--mantine-other-blueHover': theme.other.blueHover,
    },
    light: {},
    dark: {},
  });

  return (
    <>
      <DefaultSeo {...SEO} />
      <Script
        src="https://cmp.osano.com/16CVv2SHSkiNfWc9/af2c3f73-3952-4e43-8231-0349479486f0/osano.js"
        defer
      />
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
      `}
      </Script>
      <ApolloProvider client={GraphQLClient}>
        <MantineProvider theme={PebbleTheme} cssVariablesResolver={resolver}>
          <EmbedContextProvider
            isEmbed={isEmbed}
            iframeTheme={iframeTheme}
            iframeLocation={iframeLocation}
          >
            <AmplitudeContextProvider>
              <GoogleContextProvider>
                <SavedSearchFilterValuesProvider>
                  <BasketProvider>
                    <ProfileContextProvider>
                      <AppShell>
                        <AppShell.Header
                          style={{
                            main: {
                              width: '100%',
                              padding: 0,
                              minHeight: '100dvh',
                            },
                          }}
                        >
                          <PebbleNavbar height={navbarHeight} />
                        </AppShell.Header>
                        <WithGuardian setGuardian={loadGuardianInIntercom}>
                          <IntercomProvider>
                            <div style={{ paddingTop: isEmbed ? 0 : navbarHeight }}>
                              {!isChromeIncognito && <SetUtmParams />}
                              {isPageLoading ? (
                                <Center style={{ height: '50vh' }}>
                                  <Pebbles />
                                </Center>
                              ) : (
                                <Component {...pageProps} isChromeIncognito={isChromeIncognito} />
                              )}
                            </div>
                          </IntercomProvider>
                        </WithGuardian>
                      </AppShell>
                    </ProfileContextProvider>
                  </BasketProvider>
                </SavedSearchFilterValuesProvider>
              </GoogleContextProvider>
            </AmplitudeContextProvider>
          </EmbedContextProvider>
        </MantineProvider>
      </ApolloProvider>
    </>
  );
};

export default MyApp;
