import { useQuery } from '@apollo/client';
import { Auth } from '@pebble/common';
import getGuardian from 'graphql/queries/getGuardian';
import { useRouter } from 'next/router';
import { createContext, FC, ReactNode, useContext } from 'react';
import { GuardianType } from 'types';

export interface ProfileContextType {
  loading: boolean;
  profile: GuardianType;
}

export const ProfileContext = createContext<ProfileContextType | null>(null);

interface ProfileContextProviderProps {
  children: ReactNode;
}

export const ProfileContextProvider: FC<ProfileContextProviderProps> = ({ children }) => {
  const userToken = Auth.useGetJWT();
  const router = useRouter();

  const { loading, data } = useQuery(getGuardian, {
    skip: !userToken,
    context: {
      headers: {
        Authorization: `${userToken}`,
      },
    },
    onError: (error) => {
      if (error.message === 'GUARDIAN_DOES_NOT_EXIST') {
        router.push('/register');
      } else {
        console.log(error, 'error');
        console.log(error);
      }
    },
  });

  const value: ProfileContextType = {
    loading,
    profile: data?.guardian,
  };

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export const useProfile = (): ProfileContextType => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used with ProfileContextProvider');
  }
  return context;
};
